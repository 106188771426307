import { Server } from "../../utils/server";
import { BASE_JOBS_API } from "../../constants/paths";
import {
  fetchHomeJobsSuccess,
  fetchingHomeJobs,
  fetchHomeJobsError,
} from "../actions/home-job-actions";

export const fetchHomeJobs = () => (dispatch) => {
  dispatch(fetchingHomeJobs());

  return Server({
    method: "get",
    url: `${BASE_JOBS_API}/recent_listings`,
  })
    .then((response) => response.data)
    .then((jobs) => dispatch(fetchHomeJobsSuccess(jobs)))
    .catch((err) => {
      const message = "Error fetching home jobs";
      dispatch(fetchHomeJobsError(message));
    });
};
