import React from "react";
import styled from "styled-components";
import NewJobItem from "../new-job-item";
import { HomeCarbonAd } from "../ads/home-carbon-ad";
import { colors, maxInnerWrapperWidth, media } from "../../../constants/theme";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { paths } from "../../../constants/paths";
import { isFavourited } from "../../../utils/favourites";
import { setFavourite } from "../../../store/actions/favourite-actions";
import { Prices } from "../../../constants/prices";
import { metaStatuses } from "../../../store/state/meta-state";
import { ComponentLoader } from "../../atoms/component-loader";
import { TextNotice } from "../../atoms/text-notice";

const Wrapper = styled.div`
  background-color: ${colors.backgroundBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  ${media.desktop`
    padding-left: 32px;
    padding-right: 32px;
  `}
`;

const Panel = styled.div`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: ${colors.white};
  max-width: ${maxInnerWrapperWidth};
  margin: 0 auto;
  padding: 40px 24px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    padding: 60px;
  `}
`;

const Description = styled.p`
  color: ${colors.lighterText};
  font-size: 18px;
  font-weight: 300;
`;

const Left = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  ${media.desktop`
    margin-right: 100px;
    width: 30%;
    margin-bottom: 0;
    text-align:left;
  `}
`;
const Right = styled.div`
  width: 100%;
  ${media.desktop`
    width: 70%;
  `}
`;

const StyledLink = styled(Link)`
  color: ${colors.linkBlue};
`;

const UnderlinedLink = styled(Link)`
  display: block;
  color: ${colors.linkBlue};
  font-size: 18px;
  font-weight: 600;
  padding: 80px 40px;
  letter-spacing: -0.04em;
  text-align: center;
`;

const AdPosition = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  position: relative;
  top: 0;
  margin: 24px 0;

  ${media.desktop`
    top: -50px;
    margin: 0;
  `}
`;

export const RecentListings = ({
  homeJobs,
  handleFavouriteClick,
  favourited,
  meta: { status },
}) => (
  <Wrapper>
    <AdPosition>
      <HomeCarbonAd />
    </AdPosition>
    <Panel>
      <Left>
        <h2>Latest Job Listings</h2>
        <Description>
          The latest remote listings we’ve received across all job categories.
        </Description>
        <StyledLink to={paths.all_jobs}>View all jobs</StyledLink>
      </Left>
      <Right>
        {(status === metaStatuses.LOADING ||
          status === metaStatuses.DEFAULT) && (
          <ComponentLoader message="Fetching recent jobs" />
        )}
        {status === metaStatuses.SUCCESS &&
          homeJobs &&
          homeJobs.map((job, i) => (
            <NewJobItem
              favourited={isFavourited(job.id, favourited)}
              handleFavouriteClick={handleFavouriteClick}
              key={i}
              job={job}
            />
          ))}
        {status === metaStatuses.FAILURE && (
          <TextNotice>Failed to fetch recent jobs</TextNotice>
        )}
      </Right>
    </Panel>
    <UnderlinedLink to={paths.post_job}>
      List your Remote Job Position for ${Prices.single_job}/30 days
    </UnderlinedLink>
  </Wrapper>
);

const mapDispatchToProps = (dispatch) => ({
  handleFavouriteClick(job) {
    dispatch(setFavourite(job));
  },
});

const mapStateToProps = (state) => {
  return {
    favourited: state.favouriteState.favourites,
    homeJobs: state.homeJobsState.entity.jobs,
    meta: state.homeJobsState.meta,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentListings);
