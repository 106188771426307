import React, { Component } from "react";
import RecentListings from "../components/recent-listings";
import { PowerSearchHero } from "../components/power-search-hero";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHomeJobs } from "../../store/thunks/home-jobs";
import { NewHero } from "../components/new-hero";
import { Footer } from "../components/footer";
import { PopularCategories } from "../components/popular-categories";
import { metaStatuses } from "../../store/state/meta-state";
import { JobsByLocation } from "../components/jobs-by-location";

class Home extends Component {
  static fetchData(store) {
    return store.dispatch(fetchHomeJobs());
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.meta.status === metaStatuses.DEFAULT) {
      this.props.fetchHomeJobs();
    }
  }

  handleScrollClick = () => {
    if (this.myRef) {
      window.scrollTo({
        top: this.myRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Remote Jobs: Design, Marketing, Programming, Writing & More
          </title>
          <meta
            name="description"
            content="Discover Remote Jobs from around the world. Give up the commute, work remotely and do what you love, daily, from anywhere. Find your perfect remote development, design, sales or marketing job today."
          />
          <meta property="og:title" content="JustRemote" />
          <meta
            property="og:image"
            content="https://s3.eu-west-2.amazonaws.com/remoteworker-live-superbrnds/Shareable/JustRemote_homepage.jpg"
          />
          <meta
            property="og:description"
            content="Discover Remote Jobs from around the world. Give up the commute, work remotely and do what you love, daily, from anywhere. Find your perfect remote development, design, sales or marketing job today."
          />
          <meta property="og:url" content="https://justremote.co" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://justremote.co" />
        </Helmet>
        <NewHero handleScrollClick={this.handleScrollClick} isHome />
        <RecentListings />
        <PopularCategories />
        <PowerSearchHero
          tracking={{ action: "PowerSearch - Homepage - BottomHero" }}
        />
        <JobsByLocation />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meta: state.homeJobsState.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomeJobs: bindActionCreators(fetchHomeJobs, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
